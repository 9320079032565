<template>
  <div>
    <div class="card card-custom">
      <div class="card-body p-0">
        <!--begin: Wizard-->
        <div
          class="wizard wizard-3"
          id="kt_wizard_v3"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <!--begin: Wizard Nav -->
          <div class="wizard-nav border-bottom mb-1 mb-lg-5">
            <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
              <div
                class="wizard-step"
                data-wizard-type="step"
                data-wizard-state="current"
              >
                <div class="wizard-label">
                  <h3 class="wizard-title">
                    <span>1</span>Haydovchi malumotlari
                  </h3>

                  <div class="wizard-bar"></div>
                </div>
              </div>
              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-label">
                  <h3 class="wizard-title">
                    <span>2</span>Kontakt malumotlari
                  </h3>
                  <div class="wizard-bar"></div>
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Nav -->

          <!--begin: Wizard Body-->
          <div class="row justify-content-center py-12 px-8 py-lg-15 px-lg-10">
            <div class="col-12">
              <!--begin: Wizard Form-->

              <!--begin: Wizard Step 1-->
              <div
                class="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <div>
                  <h4 class="mb-10 font-weight-bold text-dark">
                    Haydovchi malumotlari
                  </h4>
                  <div class="form-group">
                    <label>Ismi</label>
                    <input
                      type="text"
                      disabled
                      class="form-control form-control-solid form-control-lg"
                      :value="getDrivers.first"
                    />
                  </div>
                  <div class="form-group">
                    <label>Familiya</label>
                    <input
                      type="text"
                      disabled
                      class="form-control form-control-solid form-control-lg"
                      :value="getDrivers.last"
                    />
                  </div>
                  <div class="form-group">
                    <label>Tug'ilgan sana</label>
                    <input
                      type="text"
                      disabled
                      class="form-control form-control-solid form-control-lg"
                      :value="getDrivers.date_birth"
                    />
                  </div>
                  <div class="form-group">
                    <label>INN</label>
                    <input
                      type="text"
                      disabled
                      class="form-control form-control-solid form-control-lg"
                      :value="getDrivers.inn"
                    />
                  </div>
                  <div class="form-group">
                    <label>Pasport raqami</label>
                    <input
                      type="text"
                      disabled
                      class="form-control form-control-solid form-control-lg"
                      :value="getDrivers.passport_num"
                    />
                  </div>
                  <div class="form-group">
                    <label>Rasm</label>
                    <input
                      type="text"
                      disabled
                      class="form-control form-control-solid form-control-lg"
                      :value="getDrivers.photo"
                    />
                  </div>
                  <div class="form-group">
                    <label>Manzil</label>
                    <input
                      type="text"
                      disabled
                      class="form-control form-control-solid form-control-lg"
                      :value="getDrivers.passport_address"
                    />
                  </div>
                  <div
                    class="
                      separator separator-primary separator-dashed
                      mt-8
                      mb-5
                    "
                  ></div>

                  <div v-if="getDrivers.driver_phone != 0">
                    <h4 class="mb-10 font-weight-bold text-dark">
                      Haydovchi kontakt malumotlari
                    </h4>
                    <div class="form-group">
                      <div
                        v-for="phonenum in getDrivers.driver_phone"
                        :key="phonenum"
                        class="mb-5"
                      >
                        <label class="font-weight-bold text-dark"
                          >Telefon raqam</label
                        >
                        <input
                          type="text"
                          disabled
                          class="
                            form-control form-control-solid form-control-lg
                          "
                          :value="phonenum.phone_number"
                        />
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="form-group">
                      <h4 class="mb-10 font-weight-bold text-danger">
                        Haydovchi malumotlari topilmadi
                      </h4>

                      <input
                        type="text"
                        value
                        placeholder="Haydovchi malumotlari topilmadi"
                        class="
                          my-2
                          form-control form-control-solid form-control-lg
                        "
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 1-->

              <!--begin: Wizard Step 2-->

              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  Haydovchi kontakt malumotlari
                </h4>
              </div>

              <!--end: Wizard Step 2-->

              <!--end: Wizard Form-->
            </div>
          </div>
          <!--end: Wizard Body-->
        </div>
        <!--end: Wizard-->
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-3.scss';
</style>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

import KTUtil from '@/assets/js/components/util'
import KTWizard from '@/assets/js/components/wizard'
export default {
  name: 'driverdetails',
  data() {
    return {}
  },
  beforeCreate() {
    this.$store.dispatch('getAllDrivers')
  },

  computed: {
    getDrivers() {
      const allDrivers = this.$store.state.requests.allDrivers
      return allDrivers.find((x) => x.id == this.$route.params.id)
    }
  },
  mounted() {
    this.initWizard()
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Haydovchi malumotlari' }])
  },

  methods: {
    initWizard() {
      this.$nextTick(() => {
        // Initialize form wizard
        const wizard = new KTWizard('kt_wizard_v3', {
          startStep: 1, // initial active step number
          clickableSteps: true // allow step clicking
        })

        // Validation before going to next page
        wizard.on('beforeNext', function (/*wizardObj*/) {
          // validate the form and use below function to stop the wizard's step
          // wizardObj.stop();
        })

        //   Change event
        wizard.on('change', function (/*wizardObj*/) {
          setTimeout(() => {
            KTUtil.scrollTop()
          }, 500)
        })
      })
    }
  }
}
</script>

<style></style>
